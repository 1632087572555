import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { showIntercomMessenger } from 'shared/helpers/Intercom';
import {
  calculateETAMeanRate,
  etaThresholds,
} from 'shared/modules/carrier-profile/core/PerformanceHelpers';
import { useCarrierPerformanceIndicators } from 'shared/modules/carrier-profile/data/CarrierPerformanceAPI';
import { WebViewAnchorButton } from 'shared/routing/WebViewLinks';

function EtaTopPerformer() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">You are a top performer!</Typography>
      <Typography color="textSecondary">
        Your drivers consistently provide their ETA for every load improving
        communications with shippers.
      </Typography>
    </Stack>
  );
}

function EtaGoodMetrics() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">
        You&apos;re on the right track, but there&apos;s still room to improve.
      </Typography>
      <Typography color="textSecondary">
        Please provide your ETA for every load to enhance this metric and
        improve communications with shippers. You are getting close to becoming
        a top performer. Keep up the good work!
      </Typography>
    </Stack>
  );
}

function EtaCanImproveMetrics() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">
        You have room for improvement on this metric.
      </Typography>
      <Typography color="textSecondary">
        Please provide your ETA for every load to enhance this metric and
        improve communications with shippers.
      </Typography>
    </Stack>
  );
}

export default function EtaDrawerContent() {
  const { data } = useCarrierPerformanceIndicators();

  const meanRate = useMemo(
    () => (data ? calculateETAMeanRate(data) : null),
    [data],
  );

  const variants = useMemo(
    () => (meanRate != null ? etaThresholds(meanRate) : null),
    [meanRate],
  );

  if (meanRate == null || variants == null) return null;

  return (
    <Stack space="small">
      {variants.top ? (
        <EtaTopPerformer />
      ) : variants.good ? (
        <EtaGoodMetrics />
      ) : (
        <EtaCanImproveMetrics />
      )}
      <Stack space="xsmall">
        <Typography variant="h4">What is counted?</Typography>
        <Typography color="textSecondary">
          The percentage of times drivers provided an ETA for Pickup and
          Deliveries.
        </Typography>
      </Stack>
      <Stack space="small">
        <Typography variant="h4">Can we help?</Typography>
        <WebViewAnchorButton
          variant="text"
          endIcon={<ChevronRight />}
          target="_blank"
          href="https://support.superdispatch.com/en/articles/7913694-carrier-tms-carrier-performance-metrics"
        >
          Read our Article
        </WebViewAnchorButton>
        {variants.canImprove && (
          <Button
            variant="text"
            endIcon={<ChevronRight />}
            onClick={() => {
              showIntercomMessenger();
            }}
          >
            Talk to Us
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
